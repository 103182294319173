.admin-container {
    margin: 0 auto;
    margin-top: 100px;
    min-height: 75vh;
    width: 100%;
    text-align: center;
}

.login-form {
    display:grid;
    grid-template-columns: auto;
}

.login-form-input, .login-form-submit {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}

.short-form {
    display:grid;
    grid-template-columns: auto;
}

.short-form-input, .short-form-submit, .lokacja-form-input, .lokacja-form-submit {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}

.lokacja-form-textarea {
    width: 500px;
    height: 300px;
}

.lokacja-form-submit {
    display: block;
}

.zdjecia {
    margin: 0 auto;
    width: 100%;
}

.zdjecie {
    display: grid;
    grid-template-columns: 50px 250px auto 100px;
}

.zdjecie-mini {
    width: 100%;
    max-width: 100%;
}

.zdjecie-mini img{
    width: 100%;
    max-width:100%;
    height: auto;
}

.zdjecie-detale {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.zdjecie-detale div{
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.zdjecie-form-input {
    height: 20px;
}

.strzalki, .zdjecie-usun {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}

.strzalki, .zdjecie-usun i {
    font-size: 30px;
}

.strzalki div:hover, .new-zdjecie i:hover, .zdjecie-usun i:hover, .new-atrakcja i:hover, .atrakcja-usun i:hover {
    color: var(--primary);
    cursor: pointer;
}

.atrakcje {
    display: grid;
    grid-template-columns: 1fr;
}

.atrakcje div{
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 50px;
}

.atrakcja-form-input {
    width: 500px;
    margin-bottom: 5px;
}