.carousel {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    height: var(--app-height);
    z-index: -2;
}

.slide1, .slide2, .slide3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    transition: opacity 1s linear;
}

.slide1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel1.jpg?alt=media');
    opacity: 1;
}

.slide2 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel3.jpg?alt=media');
    opacity: 0;
}

.slide3 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel4.jpg?alt=media');
    opacity: 0;
}

.carousel-powerline {
    position: absolute;
    top: 20vh;
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: white;
}

.carousel-powerline h1, .carousel-powerline h2 {
    margin-left: 10px;
    margin-right: 10px;
}

.carousel-powerline h1 {
    text-shadow: 1px 1px 4px var(--primary), -1px -1px 4px var(--primary);
}
.carousel-powerline h2 {
    color: var(--primary);
    text-shadow: 1px 1px 2px white, 0px 1px 2px white, 0px 0px 2px white, 1px 0px 2px white,
                -1px -1px 2px white, 0px -1px 2px white, -1px 0px 2px white;
}

.carousel-actionbar {
    position: absolute;
    bottom: 70px;
    width: 100%;
}

.carousel-text {
    margin: 0 auto;
    text-align: center;
    max-width: 40%;
    font-size: 20px;
    text-shadow: 1px 1px 2px white, 0px 1px 2px white, 0px 0px 2px white, 1px 0px 2px white,
                -1px -1px 2px white, 0px -1px 2px white, -1px 0px 2px white;
}

.carousel-text:hover {
    color: var(--primary);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {

    .carousel-powerline {
        font-size: 40px;
    }

    .hero-icons {
        grid-template-columns: 1fr 1fr;
    }

    .reviews {
        display: block;
    }

    .review {
        min-height: 0vh;
        margin: 0;
        padding: 20px;
    }

}

@media only screen and (max-width: 900px) {

    .carousel-powerline {
        font-size: 30px;
    }
}

@media only screen and (max-width: 600px) {

    .carousel-powerline {
        font-size: 20px;
    }


    .carousel-powerline h2 {
        margin-top: 10vh;
    }

    .carousel-text {
        padding: 5px;
        max-width: 80%;
        font-size: 10px;
    }

    .carousel-actionbar {
        bottom: 60px;
    }

    .house {
        padding: 0;
        display: block;
    }
    
    .house-image {
        border-radius: 0px;
        height: 40vh;
    }
}

@media only screen and (min-width: 1500px) {
    .carousel, .carousel-actionbar {
        margin: 0 auto;
        width: 1500px;
    }
}

@media only screen and (max-height: 900px) and (orientation: landscape) {

    .carousel-powerline {
        font-size: 30px;
    }
    
    .carousel-actionbar {
        bottom: 60px;
    }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {

    .carousel-powerline {
        top: 60px;
        font-size: 20px;
    }

    .carousel-text {
        font-size: 14px;
    }

    .carousel-actionbar {
        bottom: 50px;
    }
}