.details-navbar-bg {
    height: 100px;
    background-color: rgb(180, 180, 180);
}

.details {
    margin: 0 auto;
    max-width: 1500px;
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.details-left {
    font-size: 20px;
}

.details-left, .details-right {
    margin: 0 20px 0 20px;
}

.details-left h1, .details-left h4{
    text-align: center;
}

.details-left div {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.details-right {
    margin-top: 10px;
    text-align: center;
}

.details-map {
    margin: 0 auto;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1200px) {

    .details {
        display: block;
    }

    .details-left {
        margin: 0 auto;
        width: 80%;
    }

}

@media only screen and (max-width: 600px) {
    .details-right {
        margin: 0;
    }

    .details-left {
        margin: 0 10px 0 10px;
        width: auto;
    }
}