.footer-container {
    min-height: 200px;
    width: 100%;
}

.footer {
    min-height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    background-color: rgb(240,240,240);
}

.footer div p a {
    color: var(--text);
    text-decoration: none;
}

.footer div p a:hover {
    color: rgb(195,31,57);
}
.footer div img {
    width: 200px;
}

@media only screen and (max-width: 900px) {
    .footer {
        grid-template-columns: 1fr 1fr;
        display: block;
        padding-top: 20px;
    }
}

@media only screen and (min-width: 1500px) {
    .footer {
        width: 1500px;
        margin: 0 auto;
    }
}