.booking-container {
    top: 150px;
    right: 0;
    height: 50px;
    width: 200px;
    position: fixed;
    z-index: 999;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary);
    color: white;
    font-style: normal;
}

.booking-submit {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: none;
    border: none;
    background: transparent;
    color: white;
    font-size: 16px;
}

.booking-submit:hover {
    color: var(--primary);
    background-color: white;
    cursor: pointer;
    border: 2px solid var(--primary);
}

@media only screen and (max-width: 700px) {
    .booking-container {
        top: 100px;
    }
}