:root {
  --bgcolor: #fff;
  --text: #000;
  --primary: rgb(195,31,57);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: var(--text);
  font-size: 16px;
  font-family: 'Cardo', sans-serif; 
  font-weight:400; 
  font-style:italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}