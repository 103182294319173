.photo-gallery-l {
    position: relative;
    width: 100%;
    max-width: 100%;
}
.photo-gallery-l img{
    max-width:100%;
    height: auto;
}

.gallery-buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 90%;
    top: 50%;
    left: 5%;
}

.gallery-buttons-left, .gallery-buttons-right {
    color: black;
    background-color: rgba(255,255,255,0.8);
    width: 38px;
    height: 38px;
    font-size: 30px;
    border-radius: 50%;
}

.gallery-buttons-left:hover, .gallery-buttons-right:hover {
    color: var(--primary);
    cursor: pointer;
}

.photo-gallery-s {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.photo-small {
    width: 100px;
    height: 100px;
    margin: 5px;
}

.photo-small img {
    max-width:100%;
    max-height: 100%;
    height: auto;
}

.photo-small:hover {
    opacity: 0.5;
    cursor: pointer;
}