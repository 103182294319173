:root {
  --bgcolor: #fff;
  --text: #000;
  --primary: rgb(195,31,57);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: #000;
  color: var(--text);
  font-size: 16px;
  font-family: 'Cardo', sans-serif; 
  font-weight:400; 
  font-style:italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.admin-container {
    margin: 0 auto;
    margin-top: 100px;
    min-height: 75vh;
    width: 100%;
    text-align: center;
}

.login-form {
    display:grid;
    grid-template-columns: auto;
}

.login-form-input, .login-form-submit {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}

.short-form {
    display:grid;
    grid-template-columns: auto;
}

.short-form-input, .short-form-submit, .lokacja-form-input, .lokacja-form-submit {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}

.lokacja-form-textarea {
    width: 500px;
    height: 300px;
}

.lokacja-form-submit {
    display: block;
}

.zdjecia {
    margin: 0 auto;
    width: 100%;
}

.zdjecie {
    display: grid;
    grid-template-columns: 50px 250px auto 100px;
}

.zdjecie-mini {
    width: 100%;
    max-width: 100%;
}

.zdjecie-mini img{
    width: 100%;
    max-width:100%;
    height: auto;
}

.zdjecie-detale {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.zdjecie-detale div{
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.zdjecie-form-input {
    height: 20px;
}

.strzalki, .zdjecie-usun {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}

.strzalki, .zdjecie-usun i {
    font-size: 30px;
}

.strzalki div:hover, .new-zdjecie i:hover, .zdjecie-usun i:hover, .new-atrakcja i:hover, .atrakcja-usun i:hover {
    color: var(--primary);
    cursor: pointer;
}

.atrakcje {
    display: grid;
    grid-template-columns: 1fr;
}

.atrakcje div{
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 50px;
}

.atrakcja-form-input {
    width: 500px;
    margin-bottom: 5px;
}
.photo-gallery-l {
    position: relative;
    width: 100%;
    max-width: 100%;
}
.photo-gallery-l img{
    max-width:100%;
    height: auto;
}

.gallery-buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 90%;
    top: 50%;
    left: 5%;
}

.gallery-buttons-left, .gallery-buttons-right {
    color: black;
    background-color: rgba(255,255,255,0.8);
    width: 38px;
    height: 38px;
    font-size: 30px;
    border-radius: 50%;
}

.gallery-buttons-left:hover, .gallery-buttons-right:hover {
    color: var(--primary);
    cursor: pointer;
}

.photo-gallery-s {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.photo-small {
    width: 100px;
    height: 100px;
    margin: 5px;
}

.photo-small img {
    max-width:100%;
    max-height: 100%;
    height: auto;
}

.photo-small:hover {
    opacity: 0.5;
    cursor: pointer;
}
.details-navbar-bg {
    height: 100px;
    background-color: rgb(180, 180, 180);
}

.details {
    margin: 0 auto;
    max-width: 1500px;
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.details-left {
    font-size: 20px;
}

.details-left, .details-right {
    margin: 0 20px 0 20px;
}

.details-left h1, .details-left h4{
    text-align: center;
}

.details-left div {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.details-right {
    margin-top: 10px;
    text-align: center;
}

.details-map {
    margin: 0 auto;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1200px) {

    .details {
        display: block;
    }

    .details-left {
        margin: 0 auto;
        width: 80%;
    }

}

@media only screen and (max-width: 600px) {
    .details-right {
        margin: 0;
    }

    .details-left {
        margin: 0 10px 0 10px;
        width: auto;
    }
}
.footer-container {
    min-height: 200px;
    width: 100%;
}

.footer {
    min-height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    background-color: rgb(240,240,240);
}

.footer div p a {
    color: var(--text);
    text-decoration: none;
}

.footer div p a:hover {
    color: rgb(195,31,57);
}
.footer div img {
    width: 200px;
}

@media only screen and (max-width: 900px) {
    .footer {
        grid-template-columns: 1fr 1fr;
        display: block;
        padding-top: 20px;
    }
}

@media only screen and (min-width: 1500px) {
    .footer {
        width: 1500px;
        margin: 0 auto;
    }
}
.carousel {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    height: var(--app-height);
    z-index: -2;
}

.slide1, .slide2, .slide3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    transition: opacity 1s linear;
}

.slide1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel1.jpg?alt=media');
    opacity: 1;
}

.slide2 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel3.jpg?alt=media');
    opacity: 0;
}

.slide3 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/carousel%2Fcarousel4.jpg?alt=media');
    opacity: 0;
}

.carousel-powerline {
    position: absolute;
    top: 20vh;
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: white;
}

.carousel-powerline h1, .carousel-powerline h2 {
    margin-left: 10px;
    margin-right: 10px;
}

.carousel-powerline h1 {
    text-shadow: 1px 1px 4px var(--primary), -1px -1px 4px var(--primary);
}
.carousel-powerline h2 {
    color: var(--primary);
    text-shadow: 1px 1px 2px white, 0px 1px 2px white, 0px 0px 2px white, 1px 0px 2px white,
                -1px -1px 2px white, 0px -1px 2px white, -1px 0px 2px white;
}

.carousel-actionbar {
    position: absolute;
    bottom: 70px;
    width: 100%;
}

.carousel-text {
    margin: 0 auto;
    text-align: center;
    max-width: 40%;
    font-size: 20px;
    text-shadow: 1px 1px 2px white, 0px 1px 2px white, 0px 0px 2px white, 1px 0px 2px white,
                -1px -1px 2px white, 0px -1px 2px white, -1px 0px 2px white;
}

.carousel-text:hover {
    color: var(--primary);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {

    .carousel-powerline {
        font-size: 40px;
    }

    .hero-icons {
        grid-template-columns: 1fr 1fr;
    }

    .reviews {
        display: block;
    }

    .review {
        min-height: 0vh;
        margin: 0;
        padding: 20px;
    }

}

@media only screen and (max-width: 900px) {

    .carousel-powerline {
        font-size: 30px;
    }
}

@media only screen and (max-width: 600px) {

    .carousel-powerline {
        font-size: 20px;
    }


    .carousel-powerline h2 {
        margin-top: 10vh;
    }

    .carousel-text {
        padding: 5px;
        max-width: 80%;
        font-size: 10px;
    }

    .carousel-actionbar {
        bottom: 60px;
    }

    .house {
        padding: 0;
        display: block;
    }
    
    .house-image {
        border-radius: 0px;
        height: 40vh;
    }
}

@media only screen and (min-width: 1500px) {
    .carousel, .carousel-actionbar {
        margin: 0 auto;
        width: 1500px;
    }
}

@media only screen and (max-height: 900px) and (orientation: landscape) {

    .carousel-powerline {
        font-size: 30px;
    }
    
    .carousel-actionbar {
        bottom: 60px;
    }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {

    .carousel-powerline {
        top: 60px;
        font-size: 20px;
    }

    .carousel-text {
        font-size: 14px;
    }

    .carousel-actionbar {
        bottom: 50px;
    }
}
.hero-icons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    background-color: rgb(240, 240, 240);
    color: black;
    font-weight: 700;
}

.hero-icons div {
    padding: 50px 20px 30px 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
}

.hero-icons div img {
    margin: 0 auto;
    height: 40px;
}

.houses {
    display: block;
}

.house {
    padding: 10px;
    display: flex;
}

.house-image {
    border-radius: 50px;
    flex: 3 1;
}

.house-image img {
    width: 100%;
}

.house-details {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 2 1;
    font-size: 23px;
}

.house-details a{
    text-decoration: none;
}

.house-details p i {
    padding-right: 10px;
}

.house-button {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    width: 120px;
    height: 40px;
    border: 2px solid var(--primary);
    color: var(--primary);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.house-button:hover {
    background-color: var(--primary);
    color: white;
}

.dividing-line {
    width: 100%;
    height: 10px;
    background-color: rgb(195,31,57);
}

.reviews {
    min-height: 30vh;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: 100%;
    position: relative;
}

.reviews::after {
    content: '';
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/gory3.jpg?alt=media&token=2e96e670-7b18-4ef4-9cd9-123b408d3ff3');
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.review {
    min-height: 20vh;
    margin: 50px 60px 0 60px;
    text-align: center;
}

@media only screen and (max-width: 900px) {

    .hero-icons {
        grid-template-columns: 1fr 1fr;
    }

    .reviews {
        display: block;
    }

    .review {
        min-height: 0vh;
        margin: 0;
        padding: 20px;
    }

}

@media only screen and (max-width: 600px) {

    .house {
        padding: 0;
        display: block;
    }
    
    .house-image {
        border-radius: 0px;
        height: 40vh;
    }
}
.navbar-container {
    top: 0;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 999;
}

.navbar {
    height: 100px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    color: var(--text);
    background-color: rgba(255,255,255,0.8);
}

.navbar-logo {
    margin: 0 auto;
}

.navbar-logo img {
    height: 100px;
}

.navbar div i {
    padding-right: 10px;
}

.navbar-phone, .navbar-mail {
    display: flex;
    height: 50%;
    border-left: 2px solid var(--primary);
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.navbar-mail {
    border-right: 2px solid var(--primary);
}

.navbar-phone-link, .navbar-mail-link {
    text-decoration: none;
    color: var(--text);
}

.navbar-phone-link:hover, .navbar-mail-link:hover {
    color: var(--primary);
    cursor: pointer;
}

.navbar-language {
    margin: 0 auto;
    width: 60px;
    height: 30px;
    background-color: white;
    cursor: default;
}

.navbar-language-switch {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    color: white;
    background-color: var(--primary);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.pl, .en {
    position: absolute;
    width: 30px;
    height: 30px;
    color: black;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.en {
    position: relative;
    left: 30px;
}

@media only screen and (min-width: 1050px) {

    .navbar-phone, .navbar-mail {
        font-size: 16px;
    }

    .navbar-phone-link::after {
        content: attr(data-phone);
    }

    .navbar-mail-link::after {
        content: attr(data-mail);
    }

}

@media only screen and (max-width: 600px) {

    .navbar div i {
        padding-right: 0;
    }
}

@media only screen and (min-width: 1500px) {
    .navbar {
        margin: 0 auto;
        width: 1500px;
    }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
    .navbar-container, .navbar, .navbar-logo img {
        height: 60px;
    }
}
.booking-container {
    top: 150px;
    right: 0;
    height: 50px;
    width: 200px;
    position: fixed;
    z-index: 999;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary);
    color: white;
    font-style: normal;
}

.booking-submit {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: none;
    border: none;
    background: transparent;
    color: white;
    font-size: 16px;
}

.booking-submit:hover {
    color: var(--primary);
    background-color: white;
    cursor: pointer;
    border: 2px solid var(--primary);
}

@media only screen and (max-width: 700px) {
    .booking-container {
        top: 100px;
    }
}
.container {
    margin: 0 auto;
    max-width: 1500px;
}
