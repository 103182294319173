.hero-icons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    background-color: rgb(240, 240, 240);
    color: black;
    font-weight: 700;
}

.hero-icons div {
    padding: 50px 20px 30px 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
}

.hero-icons div img {
    margin: 0 auto;
    height: 40px;
}

.houses {
    display: block;
}

.house {
    padding: 10px;
    display: flex;
}

.house-image {
    border-radius: 50px;
    flex: 3;
}

.house-image img {
    width: 100%;
}

.house-details {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 2;
    font-size: 23px;
}

.house-details a{
    text-decoration: none;
}

.house-details p i {
    padding-right: 10px;
}

.house-button {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    width: 120px;
    height: 40px;
    border: 2px solid var(--primary);
    color: var(--primary);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.house-button:hover {
    background-color: var(--primary);
    color: white;
}

.dividing-line {
    width: 100%;
    height: 10px;
    background-color: rgb(195,31,57);
}

.reviews {
    min-height: 30vh;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: 100%;
    position: relative;
}

.reviews::after {
    content: '';
    background-image: url('https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/gory3.jpg?alt=media&token=2e96e670-7b18-4ef4-9cd9-123b408d3ff3');
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.review {
    min-height: 20vh;
    margin: 50px 60px 0 60px;
    text-align: center;
}

@media only screen and (max-width: 900px) {

    .hero-icons {
        grid-template-columns: 1fr 1fr;
    }

    .reviews {
        display: block;
    }

    .review {
        min-height: 0vh;
        margin: 0;
        padding: 20px;
    }

}

@media only screen and (max-width: 600px) {

    .house {
        padding: 0;
        display: block;
    }
    
    .house-image {
        border-radius: 0px;
        height: 40vh;
    }
}