.navbar-container {
    top: 0;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 999;
}

.navbar {
    height: 100px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    color: var(--text);
    background-color: rgba(255,255,255,0.8);
}

.navbar-logo {
    margin: 0 auto;
}

.navbar-logo img {
    height: 100px;
}

.navbar div i {
    padding-right: 10px;
}

.navbar-phone, .navbar-mail {
    display: flex;
    height: 50%;
    border-left: 2px solid var(--primary);
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.navbar-mail {
    border-right: 2px solid var(--primary);
}

.navbar-phone-link, .navbar-mail-link {
    text-decoration: none;
    color: var(--text);
}

.navbar-phone-link:hover, .navbar-mail-link:hover {
    color: var(--primary);
    cursor: pointer;
}

.navbar-language {
    margin: 0 auto;
    width: 60px;
    height: 30px;
    background-color: white;
    cursor: default;
}

.navbar-language-switch {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    color: white;
    background-color: var(--primary);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.pl, .en {
    position: absolute;
    width: 30px;
    height: 30px;
    color: black;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.en {
    position: relative;
    left: 30px;
}

@media only screen and (min-width: 1050px) {

    .navbar-phone, .navbar-mail {
        font-size: 16px;
    }

    .navbar-phone-link::after {
        content: attr(data-phone);
    }

    .navbar-mail-link::after {
        content: attr(data-mail);
    }

}

@media only screen and (max-width: 600px) {

    .navbar div i {
        padding-right: 0;
    }
}

@media only screen and (min-width: 1500px) {
    .navbar {
        margin: 0 auto;
        width: 1500px;
    }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
    .navbar-container, .navbar, .navbar-logo img {
        height: 60px;
    }
}